<template>
  <v-main class="main-bgc">
    <v-container fluid>
      <v-layout align-center justify-center fill-height>
        <v-row class=" d-flex justify-center">
          <v-col md="6">
            <v-card class="p-6">
              <v-card-title>
                <h3 class="text-center w-100">
                  {{ $t(`Stripe Account has been successfully confirmed !`) }}
                </h3>
              </v-card-title>
              <v-card-actions>
                <v-row class=" d-flex justify-center">
                  <v-btn to="/account/settings" color="primary">
                    {{ $t("Go to account settings") }}
                  </v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { confirmEmail } from "@/api";
export default {
  data: () => ({}),
  methods: {},
  mounted() {
    if (this.$route.query.confirmation_token) {
      confirmEmail(this.$route.query);
    }
  }
};
</script>

<style></style>
